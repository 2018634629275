/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

// control session
import Session from "module/session/Session";
// module user
import User from "module/user/User";
// module project
import Project from "module/project/Project";

import Platform from "module/platform/Platform";

import Funding from "module/funding/Funding";
import { NICE_BANK_CODE } from "../../lib/global_variable";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function UserCreate() {
  const [data, setData] = useState({});
  const [projectData, setProjectData] = useState([]);
  const [platformData, setPlatformData] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const [isIdentified, setisIdentified] = useState(false);
  const [selectedState, setSelectedState] = useState("");

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id") ?? "";

  const sessionInfo = Session.getSession();

  var pageSize = 5000;
  var page = 1;

  const state = {
    O1: "정산신청",
    CD: "정산취소",
    HD: "정산보류",
    OD: "정산완료"
  };

  //platform class
  let platform = new Platform(process.env.REACT_APP_PLATFORMAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  //contestant class
  let project = new Project(process.env.REACT_APP_PROJECTAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  //funding class
  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  //user class
  let user = new User(process.env.REACT_APP_USERAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  useEffect(() => {
    if (id) {
      getData();
      getProjectData();
      getPlatformData();
      getFundingData();
    }
  }, [id]);

  useEffect(() => {
    setSelectedState(data.status ?? "");
  }, [data]);

  const getData = async () => {
    var result = await user.findOne("id=" + id);
    setData(result.data.data);
    setisIdentified(result.data.data.identified);
  };

  const getPlatformData = async (where) => {
    var param = "?pageSize=5000&currentPage=0";
    var result = await platform.findAll(param);

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].fundingIncomePlatformId) {
          result_arr.push({
            value: result.data.data[i].fundingIncomePlatformId,
            label: result.data.data[i].name
          });
        }
      }
    }

    await setPlatformData(result_arr);
  };

  const getProjectData = async (where) => {
    var param = "?pageSize=5000&currentPage=0";
    var result = await project.findAll(param);

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].contestantId) {
          result_arr.push({
            value: result.data.data[i].contestantId,
            label: result.data.data[i].synopsisTitle
          });
        }
      }
    }

    await setProjectData(result_arr);
  };

  const getFundingData = async (where) => {
    var param = "?pageSize=5000&currentPage=0";
    var result = await funding.findAll(param);

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].contestantId) {
          result_arr.push({
            value: result.data.data[i].contestantId,
            label: result.data.data[i].synopsisTitle
          });
        }
      }
    }

    await setFundingData(result_arr);
  };

  const handleSubmit = async () => {
    const updatedData = {
      id: id,
      isIdentified: isIdentified
    };

    const result = await user.update(updatedData);

    if (result.data.code === 200) {
      window.location.href = "/admin/user";
    } else {
      alert("업데이트 실패");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                <h3 className="Category">회원상세정보</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>가입 승인 여부</label>
                        <div className="ml-3">
                          <label>
                            <Input
                              type="radio"
                              name="isIdentified"
                              value="true"
                              checked={isIdentified === true}
                              onChange={() => setisIdentified(true)}
                            />
                            승인
                          </label>
                          <label className="ml-5">
                            <Input
                              type="radio"
                              name="isIdentified"
                              value="false"
                              checked={isIdentified === false}
                              onChange={() => setisIdentified(false)}
                            />
                            미승인
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>이메일</label>
                        <Input
                          type="text"
                          value={data?.email ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>닉네임</label>
                        <Input
                          type="text"
                          value={data?.nickName ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>계좌 소유자 이름</label>
                        <Input
                          type="text"
                          value={data?.accountHolderName ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>은행 계좌</label>
                        <Input
                          type="text"
                          value={data?.bankAccount ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>은행 이름</label>
                        {NICE_BANK_CODE.map((option, index) => {
                          if (option.value === data.bankName) {
                            return (
                              <p key={index} className="mt-3 text-white">
                                {option.label}
                              </p>
                            );
                          }
                          return null;
                        })}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>사업자 번호</label>
                        <Input
                          type="text"
                          value={data?.businessNumber ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>전화번호</label>
                        <Input
                          type="text"
                          value={data?.callNumber ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>국가 코드</label>
                        <Input
                          type="text"
                          value={data?.countryCode ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>생성일</label>
                        <Input
                          type="text"
                          value={data?.createdAt ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>기업 여부</label>
                        <Input
                          type="text"
                          value={data?.enterprise ? "Yes" : "No"}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>파일 이름</label>
                        <Input
                          type="text"
                          value={data?.fileName ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>아이디</label>
                        <Input
                          type="text"
                          value={data?.id ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>인증 여부</label>
                        <Input
                          type="text"
                          value={data?.identified ? "Yes" : "No"}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>초대자 이메일</label>
                        <Input
                          type="text"
                          value={data?.inviterEmail ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>밈아이디</label>
                        <Input
                          type="text"
                          value={data?.mymId ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>이름</label>
                        <Input
                          type="text"
                          value={data?.name ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>전화번호</label>
                        <Input
                          type="text"
                          value={data?.phoneNum ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>티켓 수</label>
                        <Input
                          type="text"
                          value={data?.ticketCount ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>사업자등록증</label>
                        {data?.uploadUrl && (
                          <>
                            <img
                              src={data.uploadUrl}
                              alt="사업자등록증"
                              className="mt-3"
                              style={{ maxWidth: "100%" }}
                            />
                            <a
                              href={data.uploadUrl}
                              download
                              className="mt-3 d-block text-white"
                            >
                              다운로드
                            </a>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="button"
                  onClick={handleSubmit}
                >
                  상태 업데이트
                </Button>
                <Button
                  className="btn-fill"
                  color="secondary"
                  type="button"
                  onClick={() => {
                    window.location.href = "/admin/user";
                  }}
                >
                  목록으로 돌아가기
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserCreate;
