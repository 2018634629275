/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// refund class 생성
import Refund from "module/refund/Refund";
// Funding class 생성
import Funding from "module/funding/Funding";


// reactstrap components
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    PaginationComponent,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

function RefundList() {

    const [list, setList] = useState([]);
    const [reload, setReload] = useState(false);
    const sessionInfo = Session.getSession();
    var pageSize = 100;

    //이미지 확장자 여부 확인 
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

    const [page, setPage] = useState(2);

    const state = {
        "CD": "구매취소",
        "O1": "구매신청",
        "O2": "승인보류",
        "OD": "승인완료",
        "R1": "환불신청",
        "R2": "환불보류",
        "RD": "환불완료",
        "OF": "결제취소"
    };


    let refund = new Refund(process.env.REACT_APP_REFUNDAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });

    let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');
    // const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    const formattedDateTime = now.toISOString().replace('Z', '');


    useEffect(() => {
        loadList(page);
        setPagination();
    }, [])

    useEffect(() => {

        if (!reload) {

            list.forEach(async e => {

                var param = "fundingId=" + e.fundingId;
                var response = await funding.findOne(param);

                if (response.data.data) {
                    e.fundingData = response.data.data;
                }

                console.log(e);
                console.log(response);
                setList([...list, e])

            });

            setReload(true);
        }

    }, [list])

    const loadList = async (page) => {
        var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);
        var result = await refund.findAll(param);

        if (result.data.data && result.data.data.length > 0) {
            var refundDatas = result.data.data;
            setList(refundDatas);
        }
    }

    const setPagination = () => {

        var startPage = page - 1;
        var endPage = page + 1;

        if (page == 1) {
            startPage = 1;
            endPage = page + 2;
        }

        var pagination = '<spanagination aria-label="Page navigation example"><spanaginationItem><spanaginationLink previous onClick={() => loadList(' + startPage + ') }/></PaginationItem>';

        for (var i = startPage; i <= endPage; i++) {
            pagination = '<spanaginationItem><spanaginationLink onClick={() => loadList(' + i + ') }/>' + i + '</PaginationItem>';
        }

        pagination += '<spanaginationItem><spanaginationLink next  onClick={() => loadList(' + endPage + ') }/></PaginationItem></Pagination>';


        document.getElementsByClassName('pagination').html = pagination;
    }

    //상태값 기준 리스트 
    const getListByState = async (e) => {

        if (e.target.value != '') {
            loadList(page);
        } else {
            var result = await refund.findStateAll(e.target.value, {
                pageSize: pageSize,
                currentPage: page - 1
            });
        }

        setList(result.data.data);
    }

    //상태값 업데이트 하기 
    const updateState = async () => {

        var updateList = document.querySelectorAll(".refundId")

        console.log(updateList);

        if (updateList.length < 1) {
            alert('상태값을 수정할 항목을 체크해주세요.');
            return false;
        } else {

            updateList.forEach(async element => {
                if (element.checked && document.getElementById('changeState').value != '') {
                    var result = await refund.update('?refundId=' + element.value, {
                        refundId: element.value,
                        payId: document.getElementById('payId_' + element.value).value,
                        ticketAmount: document.getElementById('ticketAmount_' + element.value).value,
                        totalAmount: document.getElementById('totalAmount_' + element.value).value,
                        ticketCount: document.getElementById('ticketCount_' + element.value).value,
                        ticketId: document.getElementById('ticketId_' + element.value).value,
                        memberId: document.getElementById('memberId_' + element.value).value,
                        createdAt: document.getElementById('createdAt_' + element.value).value,
                        deletedAt: document.getElementById('deletedAt_' + element.value).value,
                        updatedAt: formattedDateTime,
                        status: document.getElementById('changeState').value
                    })
                }


            });

            // alert('상태값을 변경하였습니다')
            // window.location.reload();
        }

    }

    // 체크 리스트 삭제 처리 
    const deleteList = async () => {

        var deleteList = document.querySelectorAll(".refundId")

        console.log(deleteList);

        if (deleteList.length < 1) {
            alert('삭제할 항목을 체크해주세요.');
            return false;
        } else {

            deleteList.forEach(async element => {
                if (element.checked) {
                    var result = await refund.update(
                        '?refundId=' + element.value, {
                        refundId: element.value,
                        payId: document.getElementById('payId_' + element.value).value,
                        ticketAmount: document.getElementById('ticketAmount_' + element.value).value,
                        totalAmount: document.getElementById('totalAmount_' + element.value).value,
                        ticketCount: document.getElementById('ticketCount_' + element.value).value,
                        ticketId: document.getElementById('ticketId_' + element.value).value,
                        memberId: document.getElementById('memberId_' + element.value).value,
                        createdAt: document.getElementById('createdAt_' + element.value).value,
                        updatedAt: document.getElementById('updatedAt_' + element.value).value,
                        deletedAt: formattedDateTime
                    }
                    )
                    console.log(result);
                }
            });

            alert('리스트를 삭제하였습니다.')
            window.location.reload();

        }

    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-tasks">
                            <CardHeader>
                                <div className="d-inline-flex">
                                    {/* <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <Label check>
                                            카테고리
                                            <Input type="select" name="pollingCategory" id="pollingCategory" className="mt-3" onChange={getListByCategory}>
                                                <option value="P01_01_01">공모전 프로젝트</option>
                                            </Input>
                                        </Label>
                                    </FormGroup> */}
                                </div>
                                <Button className="btn btn-danger" style={{ float: "right" }} onClick={deleteList}>삭제하기</Button>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive">
                                    <Table>
                                        <colgroup>
                                            <col width="5%" />
                                            <col width="7%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="7%" />
                                            <col width="7%" />
                                            <col width="7%" />
                                        </colgroup>
                                        <thead>
                                            <th></th>
                                            <th>환불상태</th>
                                            <th>티켓정보</th>
                                            <th>티켓금액</th>
                                            <th>티켓갯수</th>
                                            <th>총결제액</th>
                                            <th>결제일시</th>
                                            <th>수정일시</th>
                                            <th>삭제일시</th>
                                        </thead>
                                        <tbody>
                                            {list && list.map((refundData, ind) => {
                                                console.log(refundData)
                                                return <tr>
                                                    <td className="w-5">
                                                        <FormGroup check>
                                                            <Input defaultValue={refundData.refund.payId} type="hidden" id={`payId_` + refundData.refund.refundId} />
                                                            <Input defaultValue={refundData.refund.ticketId} type="hidden" id={`ticketId_` + refundData.refund.refundId} />
                                                            <Input defaultValue={refundData.refund.ticketAmount} type="hidden" id={`ticketAmount_` + refundData.refund.refundId} />
                                                            <Input defaultValue={refundData.refund.totalAmount} type="hidden" id={`totalAmount_` + refundData.refund.refundId} />
                                                            <Input defaultValue={refundData.refund.ticketCount} type="hidden" id={`ticketCount_` + refundData.refund.refundId} />
                                                            <Input defaultValue={refundData.refund.memberId} type="hidden" id={`memberId_` + refundData.refund.refundId} />
                                                            <Input defaultValue={refundData.refund.createdAt} type="hidden" id={`createdAt_` + refundData.refund.refundId} />
                                                            <Input defaultValue={refundData.refund.updatedAt} type="hidden" id={`updatedAt_` + refundData.refund.refundId} />
                                                            <Input defaultValue={refundData.refund.deletedAt} type="hidden" id={`deletedAt_` + refundData.refund.refundId} />
                                                            <Label check>
                                                                <Input defaultValue={refundData.refund.refundId} type="checkbox" className="refundId" />
                                                                <span className="form-check-sign">
                                                                    <span className="check" />
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <a href={`/admin/refundView?refundId=` + refundData.refund.refundId} style={{ cursor: "pointer" }}>
                                                            <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                {state[refundData.refund.status]}
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={`/admin/refundView?refundId=` + refundData.refund.refundId} style={{ cursor: "pointer" }}>
                                                            <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                {refundData.funding ? refundData.funding.synopsisTitle : ''}
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={`/admin/refundView?refundId=` + refundData.refund.refundId} style={{ cursor: "pointer" }}>
                                                            <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                {refundData.refund.ticketAmount ? refundData.refund.ticketAmount.toLocaleString() : 0} 원
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {refundData.refund.ticketCount ? refundData.refund.ticketCount.toLocaleString() : 0} 개
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {refundData.refund.ticketCount &&  refundData.refund.ticketAmount ? (Number(refundData.refund.ticketCount) * Number(refundData.refund.ticketAmount)).toLocaleString() : 0} 원
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {refundData.refund.createdAt ?? ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {refundData.refund.updatedAt ?? ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {refundData.refund.deletedAt ?? ''}
                                                        </span>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className="pagination">

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <span>
                    선택된 결제상태
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                        <Label check>
                            <Input type="select" name="changeState" id="changeState" onChange={updateState}>
                                <option value="">환불상태</option>
                                <option value="R1">환불신청</option>
                                <option value="R2">환불보류</option>
                                <option value="RD">환불완료</option>
                            </Input>
                        </Label>
                    </FormGroup>
                    으로 변경
                </span>
            </div>
        </>
    );
}

export default RefundList;
