/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NICE_BANK_CODE } from "../../lib/global_variable"
// control session
import Session from "module/session/Session";
// module pay 
import Pay from "module/pay/Pay";
// Funding class 생성
import Funding from "module/funding/Funding";
// Ticket class 생성
import Ticket from "module/ticket/Ticket";
// User class 생성
import User from "module/user/User";

// reactstrap components
import {
  Button,
  Card,
  Label,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function PayView() {

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [selected, setSelected] = useState('');
  const [contestantId, setContestantId] = useState('');

  const queryParameters = new URLSearchParams(window.location.search)
  const payId = queryParameters.get("payId")

  const sessionInfo = Session.getSession();

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');
  // const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  const formattedDateTime = now.toISOString().replace('Z', '');

  const category = {
    "P01_01_01": "시놉시스 작가 발굴 공모전"
  };


  //pay class
  let pay = new Pay(process.env.REACT_APP_PAYAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  let ticket = new Ticket(process.env.REACT_APP_TICKETAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  let user = new User(process.env.REACT_APP_USERAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  const state = {
    "CD": "구매취소",
    "O1": "구매신청",
    "O2": "승인보류",
    "OD": "승인완료",
    "R1": "환불신청",
    "R2": "환불보류",
    "RD": "환불완료",
    "OF": "결제취소"
  };

  useEffect(() => {
    if (payId != '' && payId != null) {
      getData();
    }
  }, [])


  const getData = async () => {
    var result = await pay.findOne('payId=' + payId)

    if (result.data.data) {
      var param = "fundingId=" + result.data.data.fundingId;
      var response = await funding.findOne(param);

      if (response.data.data) {
        result.data.data.fundingData = response.data.data;
      }

      var param = "ticketId=" + result.data.data.ticketId;
      var response = await ticket.findOne(param);

      if (response.data.data) {
        result.data.data.ticketData = response.data.data;
      }

      var param = "id=" + result.data.data.memberId;
      var response = await user.findOne(param);

      if (response.data.data) {
        result.data.data.userData = response.data.data;
      }

      setData(result.data.data);
    }

  }

  //상태값 업데이트 하기 
  const updateState = async () => {
    var created_at = document.getElementById('createdAt_' + payId).value;

    if(created_at.length == 22){
      created_at = created_at + '1';
    } 

    var result = await pay.update('?payId=' + payId, {
      payId: payId,
      status: document.getElementById('changeState').value
    })

    console.log(result);

    if (result.data.code == 200) {
      alert('상태값 변경을 완료 하였습니다.');
      window.location.reload();
    } else {
      alert('상태값 변경에 실패 하였습니다.');
    }
  }

  //결제유형 하기 
  const updatePrivate = async () => {

    var result = await pay.update('?payId=' + payId, {
      payId: payId,
      private: document.getElementById('changePrivate').value == 'false' ? false : true,
    })

    if (result.data.code == 200) {
      alert('유형 변경을 완료 하였습니다.');
      window.location.reload();
    } else {
      alert('유형 변경에 실패 하였습니다.');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Input defaultValue={data.payId} type="hidden" id={`payId_` + data.payId} />
          <Input defaultValue={data.fundingId} type="hidden" id={`fundingId_` + data.payId} />
          <Input defaultValue={data.ticketAmount} type="hidden" id={`ticketAmount_` + data.payId} />
          <Input defaultValue={data.totalAmount} type="hidden" id={`totalAmount_` + data.payId} />
          <Input defaultValue={data.ticketCount} type="hidden" id={`ticketCount_` + data.payId} />
          <Input defaultValue={data.private} type="hidden" id={`private_` + data.payId} />
          <Input defaultValue={data.status} type="hidden" id={`status_` + data.payId} />
          <Input defaultValue={data.ticketId} type="hidden" id={`ticketId_` + data.payId} />
          <Input defaultValue={data.memberId} type="hidden" id={`memberId_` + data.payId} />
          <Input defaultValue={data.createdAt} type="hidden" id={`createdAt_` + data.payId} />
          <Input defaultValue={data.updatedAt} type="hidden" id={`updatedAt_` + data.payId} />
          <Input defaultValue={data.confirmationAt} type="hidden" id={`confirmationAt_` + data.payId} />
          <Input defaultValue={data.deletedAt} type="hidden" id={`deletedAt_` + data.payId} />
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                <h3 className="Category">결제 정보</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>주문번호</label>
                    <p>{data.payId ? data.payId : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>카테고리</label>
                    <p>{data.fundingData ? data.fundingData.categoryCode : 'P01_01_01'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>펀딩제목</label>
                    <p>{data.fundingData ? data.fundingData.synopsisTitle : '시놉시스 타이틀'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>펀딩시작일</label>
                    <p>{data.fundingData ? data.fundingData.startAt : '시놉시스 타이틀'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>펀딩마감일</label>
                    <p>{data.fundingData ? data.fundingData.endAt : '시놉시스 타이틀'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>티켓명</label>
                    <p>{data.ticketData ? data.ticketData.ticketName : '티켓'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>티켓유형</label>
                    <p>{data.ticketData ? (data.ticketData.type == 'CONTENT' ? '콘텐츠 제작 티켓' : '밈카드 제작 티켓') : '밈카드 제작 티켓'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>티켓금액</label>
                    <p>{data.ticketAmount ? data.ticketAmount : 0}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>티켓갯수</label>
                    <p>{data.ticketCount ? data.ticketCount : 0}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>결제상태</label>
                    <p>{data.status ? state[data.status] : '구매요청'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>결제유형</label>
                    <p>{data.private ? '프라이빗' : '퍼블릭'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>구매자아이디</label>
                    <p>{data.userData ? data.userData.email : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>닉네임</label>
                    <p>{data.userData ? data.userData.nickName : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>은행명</label>
                    <p>
                      {(data.userData && data.userData.bankName) &&
                        NICE_BANK_CODE.map((option, index) => {
                          if (option.value === data.userData.bankName) {
                            return <p>{option.label}</p>
                          }
                        })}
                    </p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>계좌번호</label>
                    <p>{data.userData ? (data.userData.bankAccount ?? '없음') : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>예금주명</label>
                    <p>{data.userData ? (data.userData.accountHolderName ?? '없음') : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>총 구매금액</label>
                    <p>{data.totalAmount ? data.totalAmount : 0}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>구매일</label>
                    <p>{data.createdAt ? data.createdAt : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>승인일</label>
                    <p>{data.confirmationAt ? data.confirmationAt : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>삭제일</label>
                    <p>{data.deletedAt ? data.deletedAt : ''}</p>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <span>
          선택된 결제상태
          <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
            <Label check>
              <Input type="select" name="changeState" id="changeState" onChange={updateState}>
                <option value="">결제상태</option>
                <option value="CD">구매취소</option>
                <option value="O1">구매신청</option>
                <option value="OD">승인완료</option>
              </Input>
            </Label>
          </FormGroup>
          으로 변경
        </span>
        <span>
          선택된 결제유형
          <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
            <Label check>
              <Input type="select" name="changePrivate" id="changePrivate" onChange={updatePrivate}>
                <option value="">유형</option>
                <option value="false">퍼블릭</option>
                <option value="true">프라이빗</option>
              </Input>
            </Label>
          </FormGroup>
          으로 변경
        </span>
      </div>
    </>
  );
}

export default PayView;
