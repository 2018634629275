/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState, Suspense } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NICE_BANK_CODE } from "../../lib/global_variable";

// control session
import Session from "module/session/Session";
// User class 생성
import User from "module/user/User";
// Funding class 생성
import Funding from "module/funding/Funding";
// User class 생성

import Platform from "module/platform/Platform";

import Paginations from "../../components/Pagination";

// reactstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  PaginationItem,
  PaginationLink,
  PaginationComponent,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

function UserList() {
  const [list, setList] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [reload, setReload] = useState(false);
  const sessionInfo = Session.getSession();
  const [userListCnt, setUserListCnt] = useState(0); //all user list
  var pageSize = 100;

  //이미지 확장자 여부 확인
  var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fundingData, setFundingData] = useState([]);
  const [platformData, setPlatformData] = useState([]);

  const offset = (page - 1) * pageSize; // 시작점과 끝점을 구하는 offset

  const state = {
    O1: "정산신청",
    CD: "정산취소",
    HD: "정산보류",
    OD: "정산완료"
  };

  let user = new User(process.env.REACT_APP_USERAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  //platform class
  let platform = new Platform(process.env.REACT_APP_PLATFORMAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  const now = new Date();
  const formattedDateTime = now.toISOString().replace("Z", "");

  useEffect(() => {
    loadList(page);
    setPagination();
  }, []);

  useEffect(() => {
    loadList(page);
  }, [page]);

  useEffect(() => {
    setPagination();
  }, [list]);

  const loadList = async (page = 1) => {
    let param = `?currentPage=${page - 1}&pageSize=100`; // 페이지네이션 적용

    if (searchStatus != "") {
      param += "&status=" + searchStatus;
    }

    if (searchOrder != "") {
      param += "&order=" + searchOrder;
    }

    var result = await user.findAll(param);

    setPage(page);

    console.log(result.data.data);

    if (result.data.data.content && result.data.data.content.length > 0) {
      var userDatas = result.data.data.content;

      setList(userDatas);
      setUserListCnt(result.data.data.totalElements); // 전체 요소 수 설정

      setPagination(result.data.data.totalPages); // 전체 페이지 수 설정
    } else {
      setList([]);
      setUserListCnt(0);
    }
  };

  const setPagination = (totalPages) => {
    setStartPage(page);
    setEndPage(totalPages);

    if (page == 1) {
      // 페이지네이션 로직 추가
    }
  };

  // 체크 리스트 삭제 처리
  const deleteList = async () => {
    // 모든 .requestId 요소들을 가져옵니다.
    const deleteList = Array.from(document.querySelectorAll(".requestId"));
    const checkedItems = deleteList.filter((element) => element.checked);

    console.log(checkedItems);

    if (checkedItems.length < 1) {
      alert("삭제할 항목을 체크해주세요.");
      return false;
    } else {
      for (const element of checkedItems) {
        // element가 input 요소가 맞는지 확인하고, value 값을 올바르게 가져옵니다.
        const id = element.value;

        if (!id) {
          console.error("삭제할 ID가 없습니다:", element);
          continue;
        }

        // 삭제 요청을 보냅니다.
        const result = await user.remove("?id=" + id);
        console.log(result);
      }

      alert("리스트를 삭제하였습니다.");
      window.location.reload();
    }
  };

  // 정렬기능추가
  const setOrderType = async (e) => {
    if (searchOrder == "") {
      setSearchOrder("asc");
    } else {
      setSearchOrder("");
    }

    loadList(page);
  };

  const handleChange = async (e) => {
    if (e.target.name == "searchStatus") {
      setSearchStatus(e.target.value);
    } else if (e.target.name == "searchType") {
      setSearchType(e.target.value);
    } else if (e.target.name == "searchKeyword") {
      setSearchKeyword(e.target.value);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <Button
                  className="btn btn-danger"
                  style={{ float: "right" }}
                  onClick={deleteList}
                >
                  삭제하기
                </Button>
              </CardHeader>
              <CardBody>
                <div
                  className="table-full-width table-responsive"
                  style={{ overflowY: "scroll", overflowX: "hidden" }}
                >
                  <Table>
                    <colgroup>
                      <col width="5%" />
                      <col width="7%" />
                      <col width="7%" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <th></th>
                      <th>이메일</th>
                      <th>닉네임</th>
                      <th>회원유형</th>
                      <th>가입승인</th>
                      <th>가입일</th>
                      <th></th>
                    </thead>
                    <tbody>
                      {list &&
                        list.map((userData, ind) => {
                          return (
                            <tr>
                              <td className="w-5">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      value={userData?.id ?? ""}
                                      type="checkbox"
                                      className="requestId"
                                    />
                                    <span className="form-check-sign">
                                      <span className="check" />
                                    </span>
                                  </Label>
                                </FormGroup>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {userData?.email ?? "이메일"}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {userData?.nickName ?? "닉네임"}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {userData.enterprise
                                    ? "기업회원"
                                    : "일반회원"}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {userData.identified
                                    ? "인증완료"
                                    : "미인증"}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {userData.createdAt ?? "0000-00-00"}
                                </span>
                              </td>
                              <td className="td-actions text-right">
                                <Link
                                  to={`/admin/userCreate?id=` + userData.id}
                                >
                                  <Button
                                    color="link"
                                    id="tooltip457194718"
                                    title=""
                                    type="button"
                                  >
                                    <i className="tim-icons icon-pencil" />
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <div
            className="pagination"
            id="pagination"
            style={{
              position: "absolute",
              left: "50%",
              bottom: "0%",
              tranform: "translate(-50%,0)"
            }}
          >
            <Paginations
              limit={pageSize}
              page={page}
              totalPosts={userListCnt}
              setPage={setPage}
            />
          </div>
        </Row>
      </div>
    </>
  );
}

export default UserList;
