/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState, Suspense } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NICE_BANK_CODE } from "../../lib/global_variable"

// control session
import Session from "module/session/Session";
// Pay class 생성
import Pay from "module/pay/Pay";
// Funding class 생성
import Funding from "module/funding/Funding";
// User class 생성
import User from "module/user/User";

import Paginations from "../../components/Pagination";

// reactstrap components
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    PaginationItem,
    PaginationLink,
    PaginationComponent,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

function PayList() {

    const [list, setList] = useState([]);
    const [searchType, setSearchType] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [searchOrder, setSearchOrder] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [reload, setReload] = useState(false);
    const sessionInfo = Session.getSession();
    const [payListCnt, setPayListCnt] = useState(0); //all pay list
    var pageSize = 100;

    //이미지 확장자 여부 확인 
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

    const [page, setPage] = useState(1);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);
    const offset = (page - 1) * pageSize; // 시작점과 끝점을 구하는 offset

    const state = {
        "CD": "구매취소",
        "O1": "구매신청",
        "O2": "승인보류",
        "OD": "승인완료",
        "R1": "환불신청",
        "R2": "환불보류",
        "RD": "환불완료",
        "OF": "결제취소"
    };


    let pay = new Pay(process.env.REACT_APP_PAYAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });

    let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });

    let user = new User(process.env.REACT_APP_USERAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');
    // const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    const formattedDateTime = now.toISOString().replace('Z', '');


    useEffect(() => {
        loadList(page);
        setPagination();
    }, [])

    useEffect(() => {
        loadList(page);
    }, [page])

    useEffect(() => {
        setPagination();
    }, [list])

    const loadList = async (page) => {
        var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);

        if (searchType != '' && searchKeyword != '') {
            switch (searchType) {
                case "nickname": param += '&nickname=' + searchKeyword; break;
                case "email": param += '&email=' + searchKeyword; break;
            }
        }

        if (searchStatus != '') {
            param += '&status=' + searchStatus;
        }

        if (searchOrder != '') {
            param += '&order=' + searchOrder;
        }

        var result = await pay.findAll(param);

        setPage(page);

        if (result.data.data && result.data.data.length > 0) {
            var payDatas = result.data.data;

            setList(payDatas);
            setPayListCnt(result.data.extraData.totalCount);

            setPagination();
        }else{
            setList([]);
            setPayListCnt(result.data.extraData.totalCount);
        }
    }

    const setPagination = () => {

        setStartPage(page);
        setEndPage((payListCnt % pageSize > 0 ? parseInt(payListCnt / pageSize) + 1 : parseInt(payListCnt / pageSize)));

        if (page == 1) {
            setStartPage(1);
        }
    }

    //상태값 업데이트 하기 
    const updateState = async () => {

        var updateList = document.querySelectorAll(".payId")

        console.log(updateList);

        if (updateList.length < 1) {
            alert('상태값을 수정할 항목을 체크해주세요.');
            return false;
        } else {

            updateList.forEach(async element => {
                if (element.checked && document.getElementById('changeState').value != '') {
                    var created_at = document.getElementById('createdAt_' + element.value).value;

                    if (created_at.length == 22) {
                        created_at = created_at + '1';
                    }

                    var result = await pay.update('?payId=' + element.value, {
                        payId: element.value,
                        status: document.getElementById('changeState').value
                    })

                    console.log(result);
                }
            });

            alert('상태값을 변경하였습니다')
            window.location.reload();

        }

    }

    //결제유형 하기 
    const updatePrivate = async () => {

        var updateList = document.querySelectorAll(".payId")

        console.log(updateList);

        if (updateList.length < 1) {
            alert('유형을 수정할 항목을 체크해주세요.');
            return false;
        } else {

            updateList.forEach(async element => {
                if (element.checked && document.getElementById('changePrivate').value != '') {

                    var result = await pay.update('?payId=' + element.value, {
                        payId: element.value,
                        fundingId: document.getElementById('fundingId_' + element.value).value,
                        ticketAmount: document.getElementById('ticketAmount_' + element.value).value,
                        totalAmount: document.getElementById('totalAmount_' + element.value).value,
                        ticketCount: document.getElementById('ticketCount_' + element.value).value,
                        private: document.getElementById('private_' + element.value).value,
                        ticketId: document.getElementById('ticketId_' + element.value).value,
                        memberId: document.getElementById('memberId_' + element.value).value,
                        createdAt: document.getElementById('createdAt_' + element.value).value,
                        deletedAt: document.getElementById('deletedAt_' + element.value).value,
                        private: document.getElementById('changePrivate').value == 'false' ? false : true,
                        confirmationAt: document.getElementById('confirmationAt_' + element.value).value,
                        updatedAt: formattedDateTime,
                    })
                }
            });

            // alert('상태값을 변경하였습니다')
            // window.location.reload();

        }

    }

    // 체크 리스트 삭제 처리 
    const deleteList = async () => {

        var deleteList = document.querySelectorAll(".payId")

        console.log(deleteList);

        if (deleteList.length < 1) {
            alert('삭제할 항목을 체크해주세요.');
            return false;
        } else {

            deleteList.forEach(async element => {
                if (element.checked) {
                    var result = await pay.update(
                        {
                            payId: element.value,
                            deletedAt: formattedDateTime
                        }
                    )
                    console.log(result);
                }
            });

            alert('리스트를 삭제하였습니다.')
            window.location.reload();

        }
    }

    // 정렬기능추가 
    const setOrderType = async (e) => {
        if(searchOrder == ''){
            setSearchOrder('asc');
        }else{
            setSearchOrder('');
        }

        loadList(page);
    }

    const handleChange = async (e) => {
        if (e.target.name == 'searchStatus') {
            setSearchStatus(e.target.value)
        } else if (e.target.name == 'searchType') {
            setSearchType(e.target.value)
        } else if (e.target.name == 'searchKeyword') {
            setSearchKeyword(e.target.value)
        } 
    };

    const downloadExcel = () => {
        const url = `/api/${process.env.REACT_APP_PAYAPI}/all/csv?pageSize=5000&currentPage=${page - 1}&order=desc`;
        window.open(url, '_blank');
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-tasks">
                            <CardHeader>
                                <Button className="btn btn-success" onClick={downloadExcel} style={{ marginRight: "10px" }}>
                                    엑셀 다운로드
                                </Button>
                                <div className="d-inline-flex">
                                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <Label check>
                                            <Input type="select" value={searchStatus} name="searchStatus" id="searchStatus" onChange={handleChange}>
                                                <option value="">결제상태</option>
                                                <option value="CD">구매취소</option>
                                                <option value="O1">구매신청</option>
                                                <option value="O2">승인보류</option>
                                                <option value="OD">승인완료</option>
                                                <option value="R1">환불신청</option>
                                                <option value="R2">환불보류</option>
                                                <option value="RD">환불완료</option>
                                                <option value="OF">결제취소</option>
                                            </Input>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <Input type="select" name="searchType" id="searchType" value={searchType} onChange={handleChange}>
                                                    <option value="">검색타입</option>
                                                    <option value="email">이메일</option>
                                                    <option value="nickname">닉네임</option>
                                                </Input>
                                            </div>
                                            <input type="text" id="searchKeyword" name="searchKeyword" value={searchKeyword} class="form-control" aria-label="검색어를 입력해주세요." onChange={handleChange}></input>
                                            <Button className="btn btn-primary" onClick={() => { loadList(page) }}>검색하기</Button>
                                        </div>
                                    </FormGroup>
                                </div>
                                <Link to='/admin/payCreate'>
                                    <Button style={{ float: "right" }}>등록</Button>
                                </Link>
                                <Button className="btn btn-danger" style={{ float: "right" }} onClick={deleteList}>삭제하기</Button>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive" style={{ overflowY: "scroll", overflowX: 'hidden' }}>
                                    <Table>
                                        <colgroup>
                                            <col width="5%" />
                                            <col width="7%" />
                                            <col width="7%" />
                                            <col width="7%" />
                                            <col width="7%" />
                                            <col width="7%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                        </colgroup>
                                        <thead>
                                            <th></th>
                                            <th>이메일</th>
                                            <th>닉네임</th>
                                            <th>결제상태</th>
                                            <th>티켓금액</th>
                                            <th>티켓갯수</th>
                                            <th>총결제액</th>
                                            <th>티켓종류</th>
                                            <th>예금주</th>
                                            <th>결제일시<i class="fa-solid fa-sort ml-2" onClick={setOrderType}></i></th>
                                            <th>수정일시</th>
                                        </thead>
                                        <tbody>
                                            {list && list.map((payData, ind) => {
                                                return <tr>
                                                    <td className="w-5">
                                                        <FormGroup check>
                                                            <Input defaultValue={payData.pay.payId} type="hidden" id={`payId_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.fundingId} type="hidden" id={`fundingId_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.ticketAmount} type="hidden" id={`ticketAmount_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.totalAmount} type="hidden" id={`totalAmount_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.ticketCount} type="hidden" id={`ticketCount_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.private} type="hidden" id={`private_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.status} type="hidden" id={`status_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.ticketId} type="hidden" id={`ticketId_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.memberId} type="hidden" id={`memberId_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.createdAt} type="hidden" id={`createdAt_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.updatedAt} type="hidden" id={`updatedAt_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.confirmationAt} type="hidden" id={`confirmationAt_` + payData.pay.payId} />
                                                            <Input defaultValue={payData.pay.deletedAt} type="hidden" id={`deletedAt_` + payData.pay.payId} />
                                                            <Label check>
                                                                <Input defaultValue={payData.pay.payId} type="checkbox" className="payId" />
                                                                <span className="form-check-sign">
                                                                    <span className="check" />
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {payData.user ? payData.user.email : ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {payData.user ? payData.user.nickName : ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href={`/admin/payView?payId=` + payData.pay.payId} style={{ cursor: "pointer" }}>
                                                            <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                {state[payData.pay.status]}
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={`/admin/payView?payId=` + payData.pay.payId} style={{ cursor: "pointer" }}>
                                                            <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                {payData.pay.ticketAmount} 원
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {payData.pay.ticketCount} 개
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {payData.pay.totalAmount} 원
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {payData.funding ? payData.funding.synopsisTitle : ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {payData.user ? payData.user.accountHolderName : ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {payData.pay.createdAt}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {payData.pay.updatedAt}
                                                        </span>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <div className="pagination" id="pagination" style={{
                        position: "absolute",
                        left: "50%",
                        bottom: "0%",
                        tranform: "translate(-50%,0)"
                    }}>
                        <Paginations limit={pageSize} page={page} totalPosts={payListCnt} setPage={setPage} />
                    </div>
                </Row>
                <span>
                    선택된 결제상태
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                        <Label check>
                            <Input type="select" name="changeState" id="changeState" onChange={updateState}>
                                <option value="">결제상태</option>
                                <option value="CD">구매취소</option>
                                <option value="O1">구매신청</option>
                                <option value="OD">승인완료</option>
                            </Input>
                        </Label>
                    </FormGroup>
                    으로 변경
                </span>
                <br />
                <span>
                    선택된 결제유형
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                        <Label check>
                            <Input type="select" name="changePrivate" id="changePrivate" onChange={updatePrivate}>
                                <option value="">유형</option>
                                <option value="false">퍼블릭</option>
                                <option value="true">프라이빗</option>
                            </Input>
                        </Label>
                    </FormGroup>
                    으로 변경
                </span>
            </div>
        </>
    );
}


export default PayList;
